import React from 'react';
import Cell from './Cell';

function Grid({ cells, type }) {

    return (
        <div className={`grid grid-cols-1 ${type} md:gap-4`}>
            {cells.map((cell, index) => (
                <div key={index} className={`${cell.colSpan} ${cell.colStart} ${cell.rowStart}`}>
                    <Cell
                        immagine={cell.immagine}
                        titolo={cell.titolo}
                        testo={cell.testo}
                        link={cell.link}
                    />
                </div>
            ))}
        </div>
    );
}

export default Grid;
