import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const ServiceRow = ({ titolo, testo, contatti, link, icona }) => {

    const [isMobile, setIsMobile] = useState(false);

    const checkScreenWidth = () => {
        if (window.innerWidth <= 991) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        checkScreenWidth();
        document.querySelector("body").classList.add("index3");
        window.addEventListener('resize', checkScreenWidth);
        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    return (

        <>
            <style>
                {`
                    .custom-li::marker {
                        color: black; /* Colore del bullet */
                    }
                    .custom-li {
                        color: white; /* Colore del testo */
                    }
                `}
            </style>

            <div className="grid md:grid-cols-12 md:gap-4 md:m-8 mx-4 my-0">

                <div className="md:col-span-2 text-gray-400 md:text-lg text-sm flex justify-start top-0 md:mb-4 mb-6">
                    <p className="font-cabin">{titolo}</p>
                </div>

                <div className="md:col-span-10 flex justify-start items-center ">
                    <a href={link}>
                        <p className="text-white font-cabin md:text-5xl text-2xl leading-snug hover:underline">
                            <img src={icona} alt={titolo} className="inline mr-4 md:h-16 md:w-16 h-8 w-8 align-middle" />
                            {testo}
                        </p>
                    </a>
                </div>

                <div className="md:col-span-10 md:row-start-2 md:col-start-3 flex justify-start items-center mb-6 md:mb-0 ml-6">
                    <ul className="text-white font-cabin md:text-xl text-lg mt-4 list-disc list-inside">
                        {contatti.map((contatto, index) => {
                            // Rimuovi "@" se il contatto inizia con esso
                            const displayText = contatto.startsWith('@') ? contatto.slice(1) : contatto;
                            const flag = contatto.startsWith('@')
                            const bulletClass = 'list-item list-inside text-white';

                            return (
                                <>
                                    {flag ? (
                                        <li key={index} className={"custom-li"}>{displayText}</li>
                                    ) : (
                                        <li key={index} className={bulletClass}>{displayText}</li>
                                    )}
                                </>
                            );
                        })}
                        <li>
                            <a href={link} target="_blank" className="hover:underline">
                                Clicca qui per maggiori informazioni
                            </a>
                        </li>

                    </ul>

                </div>


            </div>

        </>

    );
};

export default ServiceRow;
