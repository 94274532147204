import React, {useEffect, useState} from 'react';
import Navbar from "../Componenti/NavBar";
import FinalLogo from "../Componenti/FinalLogo";
import Grid from "../Componenti/Grid";
import NavBarPhones from "../Componenti/NavBarPhones";
import "../fade.css"
import LogoAnimation from "../Componenti/LogoAnimations";
import Footer from "../Componenti/Footer";

function Gallery({isMenuOpen, setIsMenuOpen, resetMenuMobile}) {
    const datiGriglia = [
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno3-compressed.webp",
            titolo: "Prospetto Laterale Esterno",
            testo: "Facciata Minimal, effetto Black Corten",
            colSpan: "md:col-span-5",
            colStart: "md:col-start-2",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/interno4-compressed-trimmed.webp",
            titolo: "Cucina",
            testo: "Cucina Open-Space con Isola",
            colSpan: "md:col-span-2",
            colStart: "",
            rowStart: "md:row-start-2",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno2-compressed.webp",
            titolo: "Prospetto Frontale Esterno",
            testo: "Ampie Vetrate, effetto Black Corten",
            colSpan: "md:col-span-4",
            colStart: "md:col-start-3",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/interno1-compressed-trimmed.webp",
            titolo: "Living Details",
            testo: "Passaggio da Zona Giorno a Zona Notte",
            colSpan: "md:col-span-2",
            colStart: "",
            rowStart: "md:row-start-3",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno-verticale-1-compressed.webp",
            titolo: "Outside Details",
            testo: "Relazione Giardino e Architettura",
            colSpan: "md:col-span-2",
            colStart: "md:col-start-3",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno-verticale-3-compressed.webp",
            titolo: "Outside Details",
            testo: "L'Ombra degli Alberi in relazione con la Facciata",
            colSpan: "md:col-span-2",
            colStart: "md:col-start-5",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno1-compressed.webp",
            titolo: "Prospetto Laterale Esterno",
            testo: "Facciata Minimal, effetto Black Corten",
            colSpan: "md:col-span-5",
            colStart: "",
            rowStart: "md:row-start-4",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/interno6-compressed.webp",
            titolo: "Open Space",
            testo: "La Libertà di vivere lo Spazio",
            colSpan: "md:col-span-4",
            colStart: "",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/interno5-compressed.webp",
            titolo: "Living",
            testo: "Relazione fra Interno ed Esterno",
            colSpan: "md:col-span-2",
            colStart: "md:col-start-5",
            rowStart: "md:row-start-5",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/interno2-compressed-trimmed.webp",
            titolo: "Living Details",
            testo: "Soft Sofa Details",
            colSpan: "md:col-span-2",
            colStart: "",
            rowStart: "md:row-start-6",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno-verticale-2-compressed.webp",
            titolo: "Outside Details",
            testo: "Black Corten, Faretto in Ottone, Vetrata Living doppia",
            colSpan: "md:col-span-2",
            colStart: "md:col-start-3",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/interno3-compressed-trimmed.webp",
            titolo: "Home Entrance Details",
            testo: "Interni su Misura",
            colSpan: "md:col-span-2",
            colStart: "md:col-start-5",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno4-compressed.webp",
            titolo: "Prospetto Frontale Esterno",
            testo: "Relazione Giardino e Architettura",
            colSpan: "md:col-span-5",
            colStart: "md:col-start-1",
            rowStart: "md:row-start-7",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno7-compressed.webp",
            titolo: "Vista Diagonale",
            testo: "Relazione Cappotto e Black Corten",
            colSpan: "md:col-span-2",
            colStart: "",
            rowStart: "md:row-start-8",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/esterno8-compressed.webp",
            titolo: "Vista Diagonale",
            testo: "Effetto Ombra, Scorcio sull'Entrata",
            colSpan: "md:col-span-4",
            colStart: "md:col-start-3",
            rowStart: "",
            link:""
        },
    ];

    const [bounce, setBounce] = useState(true);

    useEffect(() => {
        resetMenuMobile()
    }, [])

    return (

        <>

            <div className={`${bounce ? "visible" : "hidden"} `}>
                <LogoAnimation bounce={bounce} setBounce={setBounce} ></LogoAnimation>
            </div>

            <div className={`${bounce ? "hidden" : "visible"} ${bounce ? "" : "animate-fade animate-ease-linear"} `}>

                <div className={`${isMenuOpen ? "block" : "hidden"} `}>
                    <NavBarPhones setIsMenuOpen={setIsMenuOpen} ></NavBarPhones>
                </div>
                <div className={`${isMenuOpen ? "hidden" : "block"} main bg-black animate-fade animate-ease-linear`}>

                    <div>
                        <Navbar isMainPage={false} setIsMenuOpen={setIsMenuOpen} isError={false} ></Navbar>
                        <hr className="my-3 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-300 to-transparent opacity-100" />                    </div>

                    <div>
                        <Grid cells={datiGriglia} type="md:grid-cols-6"></Grid>
                    </div>

                    <hr className="my-0 h-px opacity-80 mx-7 mt-8" />

                    <div>
                        <FinalLogo></FinalLogo>
                    </div>

                    <div>
                        <Footer/>
                    </div>
                </div>

            </div>
        </>

    );
}

export default Gallery;
