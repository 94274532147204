import React from 'react';
import { Link } from 'react-router-dom';

const Cell = ({ immagine, testo, titolo, link }) => {
    const isLink = link !== "";
    const isVideo = immagine.endsWith('.mp4')

    const mediaContent = isVideo ? (
        <video controls={false} loop={true} preload="auto" muted autoPlay={true} playsInline={true} className="block w-full">
            <source src={immagine} type={`video/mp4`} />
            Il tuo browser non supporta il tag video.
        </video>
    ) : (
        <img src={immagine} alt={testo} className="block h-full" />
    );

    return (
        <div className="flex flex-col text-left md:px-10 md:py-10 px-3 pb-24">
            {isLink ? (
                <Link to={link}>
                    {mediaContent}
                    <h2 className="block pt-6 font-cabin text-white md:text-4xl text-3xl max-w-[600px] md:hover:text-orange-700">{titolo}</h2>
                    <p className="block py-2 font-zilla text-white md:text-3xl text-xl max-w-[600px] hover:underline">{testo}</p>
                </Link>
            ) : (
                <>
                    {mediaContent}
                    <h2 className="block pt-6 font-cabin text-white md:text-4xl text-3xl max-w-[600px]  hover:text-gray-500">{titolo}</h2>
                    <p className="block py-2 font-zilla text-white md:text-3xl text-xl max-w-[600px]">{testo}</p>
                </>
            )}
        </div>
    );
};

export default Cell;
