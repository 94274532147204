import logo from '../img/logospazioBackground.svg'
import logoMobile from '../img/logospazioMobileBackground.svg'
import React, {useEffect, useRef} from "react";
import { toast } from 'sonner'
import background from '../img/background-compressed.mp4'


const Background = () => {

    const videoRef = useRef(null);
    useEffect(() => {

        const playVideo = async () => {
            try {
                await videoRef.current.play();
            } catch (error) {
                console.log("Errore di riproduzione:", error);

                if (error.name === "NotAllowedError" || error.message.includes("autoplay")) {
                    setTimeout(() => {
                        toast.warning('Risparmio Energetico Attivo', {
                            description: 'I contenuti multimediali potrebbero non funzionare correttamente'
                        });
                    }, 5000);
                }

            }
        };

        playVideo();
    }, []);

    return (
        <>
            <div className="hidden md:block">
                <img src={logo} alt="Logo" className="absolute top-0 left-0 z-20 mr-1 p-10 mix-blend-difference"/>
            </div>

            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center z-30 md:hidden mix-blend-difference">
                <img src={logoMobile} alt="Logo Mobile" className="w-40 h-40 mix-blend-difference animate-spin animate-infinite animate-duration-[20000ms]"/>
            </div>

            <div>
                <video ref={videoRef} controls={false} loop={true} preload="auto" muted autoPlay={true} playsInline={true}
                       className="w-full h-screen object-cover"
                       style={{ height: 'calc(100vh - 56.875px)' }}>
                    <source src="https://www.spaziodifferente.com/media/videos/background-compressed.mp4" type="video/mp4"/>
                </video>
            </div>
        </>
    );
};

export default Background;