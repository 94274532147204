import React, { useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import logo from "../img/logo.png"

const Navbar = ({ isMainPage, setIsMenuOpen, isError }) => {

    const location = useLocation();
    const [hoveredLink, setHoveredLink] = useState(null);

    const isActive = (pathname) => location.pathname === pathname;
    const isHovering = () => hoveredLink !== null;

    return (
        <>
            <nav className="z-10 relative w-full px-4 pt-3 flex justify-between items-center border-black bg-transparent">
                <Link to="/">
                    <img src={logo} alt="Logo" className="h-6 md:h-8" />
                </Link>
                <div className="md:hidden">

                    <button onClick={setIsMenuOpen} className={`block font-cabin text-${isError ? 'black' : 'white'} text-2xl underline`}>
                        MENU
                    </button>

                </div>
                <div id="navbar" className={`hidden md:flex flex-col md:flex-row md:items-center bg-black md:bg-transparent py-2 md:py-0 w-full md:w-auto space-x-8 px-2`}>
                    {isMainPage ? (
                        // isMainPage == true
                        <>
                            <Link to="/storia" className="block font-cabin text-white text-2xl custom-underline">Storia</Link>
                            <Link to="/servizi" className="block font-cabin text-white text-2xl custom-underline">Servizi</Link>
                            <Link to="/galleria" className="block font-cabin text-white text-2xl custom-underline">Gallery</Link>
                            <Link to="/contatti" className="block font-cabin text-white text-2xl custom-underline">Contatti</Link>
                        </>
                    ) : (
                        // isMainPage == false
                        <>
                            <Link
                                to="/storia"
                                className={`block font-cabin text-2xl transition duration-1000 ${isActive('/storia') && !isHovering() ? 'text-white' : 'text-gray-500'} ${!isActive('/storia') && hoveredLink === '/storia' ? 'hover:text-white' : ''}`}
                                onMouseEnter={() => setHoveredLink('/storia')}
                                onMouseLeave={() => setHoveredLink(null)}
                            >
                                Storia
                            </Link>
                            <Link
                                to="/servizi"
                                className={`block font-cabin text-2xl transition duration-1000 ${isActive('/servizi') && !isHovering() ? 'text-white' : 'text-gray-500'} ${!isActive('/servizi') && hoveredLink === '/servizi' ? 'hover:text-white' : ''}`}
                                onMouseEnter={() => setHoveredLink('/servizi')}
                                onMouseLeave={() => setHoveredLink(null)}
                            >
                                Servizi
                            </Link>
                            <Link
                                to="/galleria"
                                className={`block font-cabin text-2xl transition duration-1000 ${isActive('/galleria') && !isHovering() ? 'text-white' : 'text-gray-500'} ${!isActive('/galleria') && hoveredLink === '/galleria' ? 'hover:text-white' : ''}`}
                                onMouseEnter={() => setHoveredLink('/galleria')}
                                onMouseLeave={() => setHoveredLink(null)}
                            >
                                Gallery
                            </Link>
                            <Link
                                to="/contatti"
                                className={`block font-cabin text-2xl transition duration-1000 ${isActive('/contatti') && !isHovering() ? 'text-white' : 'text-gray-500'} ${!isActive('/contatti') && hoveredLink === '/contatti' ? 'hover:text-white' : ''}`}
                                onMouseEnter={() => setHoveredLink('/contatti')}
                                onMouseLeave={() => setHoveredLink(null)}
                            >
                                Contatti
                            </Link>
                        </>

                    )}

                </div>
            </nav>

        </>
    );
};

export default Navbar;
