import React, {useEffect, useState} from 'react';
import Navbar from "../Componenti/NavBar";
import FinalLogo from "../Componenti/FinalLogo";
import {TypeAnimation} from "react-type-animation";
import Info from "../Componenti/Info";
import NavBarPhones from "../Componenti/NavBarPhones";
import "../fade.css"
import LogoAnimation from "../Componenti/LogoAnimations";
import Footer from "../Componenti/Footer";

function Servizi({isMenuOpen, setIsMenuOpen, resetMenuMobile,isMobile}) {

    //NON RIMUOVERE typingStatus....
    const [typingStatus, setTypingStatus] = useState(1);
    const speedWritingAnimation = useState(70)

    const [bounce, setBounce] = useState(true);

    const infos = [
        {
            titolo: "Arredamento",
            testo: "Differente",
            contatti : [
                "Email: info@studiodifferente.com",
                "Telefono: +39 366 2601146",
                "Indirizzo: Piazza Trento Trieste 3",
                "@San Giorgio di Piano (BO) "
            ],
            link : "https://www.studiodifferente.com/",
            icona: "https://www.spaziodifferente.com/media/img/differente.png"

        },
        {
            titolo: "Outdoor pergole e piscine",
            testo: "T&T Solution",
            contatti : [
                "Email: commerciale@tetsolution.com",
                "Telefono: 051 6630073",
                "Indirizzo: Piazza Trento Trieste",
                "@ San Giorgio di Piano (BO) "

            ],
            link : "https://www.tetsolution.com/",
            icona: "https://www.spaziodifferente.com/media/img/tet.png"

        },
        {
            titolo: "Mediazione Immobiliare",
            testo: "Vicini di Casa",
            contatti : [
                "Email: bohba@tecnocasa.it",
                "Telefono: 051 6630832",
                "Indirizzo: Via Celeste Cesari 6",
                "@San Giorgio di Piano (BO) "

            ],
            link : "https://www.vicinidicasa.net/",
            icona: "https://www.spaziodifferente.com/media/img/vicinidicasa.png"
        },
        {
            titolo: "Capitolato",
            testo: "Il Capitolato",
            contatti : [
                "Informazioni Generali",
                "Tecniche Utilizzate",
                "Impianti e Avvertenze",
                "Sistemazioni Esterne"
            ],
            icona : "https://www.spaziodifferente.com/media/img/logo-singolo.svg",
            link : "https://www.spaziodifferente.com/pdf/SD_Capitolato_di_Vendita_DIGITALE.pdf"
        }
    ];

    useEffect(() => {
        resetMenuMobile()
    }, [])

    return (
        <>
            <div className={`${bounce ? "visible" : "hidden"} `}>
                <LogoAnimation bounce={bounce} setBounce={setBounce} ></LogoAnimation>
            </div>

            <div className={`${bounce ? "hidden" : "visible"} ${bounce ? "" : "animate-fade animate-ease-linear"} `}>
                <div className={`${isMenuOpen ? "block" : "hidden"}`}>
                    <NavBarPhones setIsMenuOpen={setIsMenuOpen} />
                </div>
                <div className={`${isMenuOpen ? "hidden" : "block"} main bg-black`}>

                    <div>
                        <Navbar isMainPage={false} setIsMenuOpen={setIsMenuOpen} isError={false} ></Navbar>
                        <hr className="my-3 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-300 to-transparent opacity-100" />
                    </div>


                    <div className="grid md:grid-cols-5 md:grid-rows-1 md:gap-4 px-6">
                        <div className="md:col-start-4 md:col-span-2 flex justify-center items-center md:py-20 py-10">
                            <video controls={false} loop={true} preload="auto" muted autoPlay={true} playsInline={true} className="block w-full">
                                <source src="https://www.spaziodifferente.com/media/videos/video4-compressed.mp4" type={`video/mp4`} />
                                Il tuo browser non supporta il tag video.
                            </video>
                        </div>

                        { !bounce ? (

                            <div className="md:row-start-1 md:col-span-3 flex justify-left items-start">
                                <div className="flex flex-col justify-left items-start bg-black text-white overflow-auto">
                                    <div className="md:text-5xl text-2xl mr-7 md:py-20 pb-10 md:h-auto sm:h-64">
                                        {isMobile ? (
                                            <p>In questa sezione troverete tutti i servizi connessi a 'Spazio Differente'</p>
                                        ) : (
                                            <p>
                                                <TypeAnimation
                                                    sequence={[
                                                        "In questa sezione troverete tutti i servizi connessi a 'Spazio Differente'",
                                                        300,
                                                        () => {
                                                            setTypingStatus(2);
                                                        },
                                                    ]}
                                                    speed={speedWritingAnimation}
                                                    repeat={1}
                                                    cursor={false}
                                                />
                                            </p>
                                        )}
                                    </div>

                                </div>
                            </div>

                        ) :  null }

                    </div>

                    <hr className="my-0 h-px opacity-80 bg-gray-700 mb-6" />

                    <Info dati={infos} flag="Servizi" />

                    <FinalLogo />

                    <div>
                        <Footer/>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Servizi;