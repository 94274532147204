import React, { useEffect, useState } from 'react';

import Navbar from "../Componenti/NavBar";
import FinalLogo from "../Componenti/FinalLogo";

import NavBarPhones from "../Componenti/NavBarPhones";
import "../fade.css";
import LogoAnimation from "../Componenti/LogoAnimations";
import Footer from "../Componenti/Footer";

const sections = [
    {
        id: 1,
        title: "Il Lusso sostenibile, a due passi da Bologna.",
        content: "Composto da un' insieme di 4 ville monofamiliari e indipendenti, il progetto di spazio differente si integra armoniosamente al tessuto urbano di San Giorgio di Piano. Intrecciandosi con la natura, gli interni delle abitazioni sono caratterizzati da ambienti moderni e funzionali.",
        image: "https://www.spaziodifferente.com/media/img/c/spdfr-3.webp",
    },
    {
        id: 2,
        title: "Il Progetto",
        content: "La struttura si distingue per le ampie aperture che permettono alla luce naturale di abbracciare gli interni, suscitando un'atmosfera unica e accogliente.",
        image: "https://www.spaziodifferente.com/media/img/c/spdfr-2.webp",
    },
    {
        id: 3,
        title: "Gli Interni",
        content: "Le quattro residenze si sviluppano interamente su un unico piano, garantendo così un'ottima distribuzione degli spazi. Gli interni si fondono con il giardino privato, il quale diventa un'oasi verde, un rifugio di tranquillità dove la natura si fonde con l'eleganza dell'architettura offrendo ai propri abitanti un luogo di pace per godere della bellezza in ogni stagione.",
        image: null,
    },
];

function Storia({ isMenuOpen, setIsMenuOpen, resetMenuMobile }) {
    const [bounce, setBounce] = useState(true);

    useEffect(() => {
        resetMenuMobile();
    }, []);

    return (
        <>
            <div className={`${bounce ? "visible" : "hidden"}`}>
                <LogoAnimation bounce={bounce} setBounce={setBounce}></LogoAnimation>
            </div>

            <div className={`${bounce ? "hidden" : "visible"} animate-fade animate-ease-linear`}>
                <div className={`${isMenuOpen ? "block" : "hidden"}`}>
                    <NavBarPhones setIsMenuOpen={setIsMenuOpen}></NavBarPhones>
                </div>

                <div className={`${isMenuOpen ? "hidden" : "block"} main bg-black animate-fade animate-ease-linear`}>
                    <Navbar isMainPage={false} setIsMenuOpen={setIsMenuOpen} isError={false}></Navbar>
                    <hr className="my-3 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-300 to-transparent opacity-100" />

                    <div className="md:grid md:grid-cols-5 gap-4">
                        {sections.map((section) => (
                            <div key={section.id} className={`flex flex-col justify-center items-left bg-black text-white md:mt-24 mt-12 md:col-span-4 ${section.id % 2 === 0 ? "md:col-start-2" : ""}`}>
                                <div className="text-white md:p-10 p-5">
                                    <h2 className="font-cabin md:text-4xl text-3xl">{section.title}</h2>
                                    <p className="font-zilla pt-8 md:text-3xl text-xl">{section.content}</p>
                                    {section.image && <img className="mt-6 md:mt-12 w-full" src={section.image} alt="img" />}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/*final spacing*/}
                    <div className="md:mt-24 mt-12"></div>

                    <FinalLogo></FinalLogo>
                    <Footer></Footer>
                </div>
            </div>
        </>
    );
}

export default Storia;
