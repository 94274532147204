import React, { useState } from 'react';
import { TypeAnimation } from 'react-type-animation';

const Intro = ({ isInit }) => {
    const [typingStatus, setTypingStatus] = useState(true);

    return (
        <div className="flex flex-col justify-center md:h-96 h-56">
            {!isInit ? (
                <p
                    className="font-lora text-white md:text-5xl text-2xl md:p-10 p-5 md:mr-[40%]"
                    style={{ lineHeight: '1.3' }}
                >
                    <TypeAnimation
                        sequence={[
                            'Vivi ogni momento, vai oltre ogni aspettativa, senza limiti di ',
                            500,
                            () => {
                                setTypingStatus(false);
                            },
                        ]}
                        speed={40}
                        style={{ fontSize: '1em' }}
                        repeat={1}
                        cursor={false}
                    />
                    <span className="font-lora" style={{ color: '#e8471c' }}>
                        {typingStatus === false && (
                            <TypeAnimation
                                sequence={[
                                    'Spazio.',
                                    1000,
                                    '',
                                    1,
                                ]}
                                speed={40}
                                style={{ fontSize: '1em' }}
                                repeat={Infinity}
                            />
                        )}
                    </span>
                </p>
            ) : null}
        </div>
    );
};

export default Intro;
