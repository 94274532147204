import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Carosello.css';
import Modal from 'react-modal';

function Carosello({
                       images,
                       autoPlay = true,
                       autoPlayInterval = 5000,
                       showArrows = true,
                       showIndicators = true,
                   }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isModalOpen]);

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'transparent',
            border: 'none',
            padding: '0',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            zIndex: '1000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };

    return (
        <div className="relative group max-w-5xl mx-auto overflow-visible">
            <h1 className="block pt-6 font-cabin text-white text-center md:text-4xl text-3xl max-w-[600px] mx-auto mb-10 md:hover:text-orange-700">
                Come procedono i lavori
            </h1>

            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={30}
                slidesPerView={1}
                navigation={showArrows}
                pagination={
                    showIndicators
                        ? { clickable: true, dynamicBullets: true }
                        : false
                }
                autoplay={
                    autoPlay
                        ? {
                            delay: autoPlayInterval,
                            disableOnInteraction: false,
                        }
                        : false
                }
                loop={images.length > 1}
                className="w-full h-full"
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                }}
            >
                {images.map((src, index) => (
                    <SwiperSlide key={index}>
                        <div className="relative w-full h-0 pb-[56.25%] overflow-hidden">
                            <img
                                src={src}
                                alt={`Slide ${index + 1}`}
                                className="absolute top-0 left-0 w-full h-full object-contain cursor-pointer"
                                onClick={() => {
                                    setSelectedImage(src);
                                    setIsModalOpen(true);
                                }}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                style={customStyles}
                contentLabel="Immagine Ingrandita"
            >
                <div className="relative">
                    {/* Pulsante di chiusura */}
                    <button
                        className="absolute top-4 right-4 text-white bg-transparent border-none focus:outline-none z-10"
                        onClick={() => setIsModalOpen(false)}
                        aria-label="Chiudi"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                    {/* Immagine ingrandita con animazione */}
                    <img
                        src={selectedImage}
                        alt="Ingrandita"
                        className="max-w-screen max-h-screen object-contain animate-zoomIn"
                    />
                </div>
            </Modal>
        </div>
    );
}

Carosello.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    autoPlay: PropTypes.bool,
    autoPlayInterval: PropTypes.number,
    showArrows: PropTypes.bool,
    showIndicators: PropTypes.bool,
};

export default Carosello;
