import Navbar from "../Componenti/NavBar";
import Grid from '../Componenti/Grid';
import Intro from '../Componenti/Intro'
import React, {useEffect, useState} from "react";
import Background from "../Componenti/Background";
import Info from "../Componenti/Info"
import FinalLogo from "../Componenti/FinalLogo";
import NavBarPhones from "../Componenti/NavBarPhones";
import Gmaps from "../Componenti/Gmaps";
import InitAnimation from "../Componenti/InitAnimation";
import "../Animate.css"
import Footer from "../Componenti/Footer";
import Carosello from "../Componenti/Carosello";

function HomePage({isMenuOpen, setIsMenuOpen, resetMenuMobile}) {

    const [isInit, setInit] = useState(true);

    const datiGriglia = [
        {
            immagine: "https://www.spaziodifferente.com/media/videos/video5-compressed.mp4",
            titolo: "Il Concept",
            testo: "Le quattro residenze si distinguono per essere interamente sviluppate su un piano unico, garantendo così una distribuzione ampia e rispettosa di tutti gli ambienti.",
            colSpan: "md:col-span-3",
            colStart: "",
            rowStart: "",
            link:"/storia"
        },
        {
            immagine: "https://www.spaziodifferente.com/media/videos/video1-compressed.mp4",
            testo: "A San Giorgio di Piano, nasce una nuova realtà, veramente differente. Il lusso diventa sostenibile, a due passi da Bologna.",
            colSpan: "md:col-span-2",
            colStart: "md:col-start-4",
            rowStart: "",
            link:""
        },
        {
            immagine: "https://www.spaziodifferente.com/media/videos/video3-compressed.mp4",
            titolo: "Il Progetto",
            testo: "Un'esperienza residenziale che coniuga stile e comfort in modo unico.",
            colSpan: "md:col-span-4",
            colStart: "md:col-start-2",
            rowStart: "row-start-2",
            link:"/galleria"
        },

        {
            immagine: "https://www.spaziodifferente.com/media/videos/video4-compressed.mp4",
            titolo: "Servizi",
            testo: "Tutti i servizi connessi alla vostra casa",
            colSpan: "md:col-span-2",
            colStart: "",
            rowStart: "md:row-start-3",
            link:"/servizi"
        },
        {
            immagine: "https://www.spaziodifferente.com/media/img/comparto-compressed.webp",
            titolo: "",
            testo: "",
            colSpan: "md:col-span-3",
            colStart: "md:col-start-3",
            rowStart: "",
            link:""
        },


    ];

    const infos = [
        {
            link:"https://www.instagram.com/tecnocasasangiorgiodipiano/",
            titolo: "Tecnocasa",
            testo: "Per vedere come si sviluppa la vostra casa venite a seguirci su instagram.",
        },
        {
            link:"https://www.instagram.com/studiodifferente/",
            titolo: "Differente",
            testo: "Resta in contatto con noi per qualsiasi domanda \'Differente\' ",
        },
    ];

    // Definisci l'array di immagini per il carosello
    const carouselImages = [
        'https://www.spaziodifferente.com/media/img/c/spdfr.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-2.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-3.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-4.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-5.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-6.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-7.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-8.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-9.webp',
        'https://www.spaziodifferente.com/media/img/c/spdfr-10.webp',
    ];

    useEffect(() => {
        resetMenuMobile()
    },[])

    return (

        <>
            {isInit && <InitAnimation setInit={setInit} />}

            <div className={`${isInit ? "hidden" : "visible"} ${isInit ? "" : "animate-fade animate-ease-in"} `}>
                <div className={`${isMenuOpen ? "block" : "hidden"} fade`}>
                    <NavBarPhones setIsMenuOpen={setIsMenuOpen} />
                </div>
                <div className={`${isMenuOpen ? "hidden" : "block"} main bg-black`}>
                    <div>
                        <Background />
                    </div>
                    <div>
                        <Navbar isMainPage={true} setIsMenuOpen={setIsMenuOpen} />
                    </div>
                    <div className="py-3">
                        <hr className="my-0 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-300 to-transparent opacity-100" />
                    </div>
                    <div>
                        <Intro isInit={isInit} />
                    </div>
                    <div>
                        <Grid cells={datiGriglia} type="md:grid-cols-5" />
                    </div>
                    <div className="md:my-10 mt-[-20px] mb-20 md:mb-40">
                        <Carosello
                            images={carouselImages}
                            autoPlay={true}
                            autoPlayInterval={5000}
                            showArrows={true}
                            showIndicators={true}
                        />
                    </div>


                    <div>
                        <Gmaps isInit={isInit} />
                    </div>
                    <div className="my-10" />
                    <div>
                        <Info dati={infos} flag="Homepage" />
                    </div>
                    <div>
                        <FinalLogo />
                    </div>
                    <div>
                        <Footer/>
                    </div>
                </div>
            </div>
        </>

    );
}

export default HomePage;
