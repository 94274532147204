import React, {useEffect} from "react";
import logo from '../img/logoAnimazioneInit.svg'
import "../Animate.css"

const InitAnimation = ({setInit}) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            setInit(false)
        }, 3500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div className="init-container h-screen">
                <div className="pb-20">
                    <img src={logo} alt="Logo" className="logo-animation" />
                </div>
            </div>

        </>

    );
};

export default InitAnimation;


