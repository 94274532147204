import React from 'react';
import icona from '../img/icons/logo-singolo.svg'
import {Link} from "react-router-dom";
const HomeRows = ({ titolo, testo, link}) => {
    return (
        <div className="grid md:grid-cols-12 md:gap-4 md:m-8 m-4">

            <div className="md:col-span-2 text-gray-400 text-sm flex justify-start md:top-0 mb-4">
                <p className="font-cabin">{titolo}</p>
            </div>

            <div className="md:col-span-10 flex justify-start items-center mb-6">
                <Link to={link}>
                    <p className="text-white font-lora md:text-5xl text-2xl leading-snug hover:underline">
                        <img src={icona} alt={titolo} className="inline mr-4 md:h-16 md:w-16 h-8 w-8 align-middle " />
                        {testo}
                    </p>
                </Link>
            </div>

        </div>
    );
};

export default HomeRows;
