import React from 'react';

const legalInfo = {
    companyName: "Differente di Tommaso Cascino",
    website: "www.studiodifferente.com",
    vatId: "04141301202",
    registryNumber: "CSCTMS01R19A785X",
    legalAddress: "Via Celeste Cesari 6, 40016 San Giorgio di Piano (BO)"
};

const Footer = () => {
    return (
        <footer className="bg-white text-gray-600 body-font">
            <div className="container px-5 py-8 mx-auto flex flex-wrap items-center justify-between">
                <p className="text-sm font-cabin text-gray-500 w-full md:w-auto mb-4 md:mb-0">
                    © {new Date().getFullYear()} {legalInfo.companyName} —
                    <a href={`https://${legalInfo.website}`} className="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">
                        {legalInfo.website}
                    </a>
                </p>
                <div className="w-full md:w-auto md:flex md:items-center">
                    <ul className="list-disc list-inside md:list-none flex flex-col md:flex-row">
                        <li className="text-sm font-cabin text-gray-500 mb-2 md:mb-0 md:px-4 md:border-r-2 md:border-gray-200">
                            P.IVA: {legalInfo.vatId}
                        </li>
                        <li className="text-sm font-cabin text-gray-500 mb-2 md:mb-0 md:px-4 md:border-r-2 md:border-gray-200">
                            Codice Fiscale: {legalInfo.registryNumber}
                        </li>
                        <li className="text-sm font-cabin text-gray-500 md:px-4">
                            Sede Legale: {legalInfo.legalAddress}
                        </li>
                    </ul>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
