import React from 'react';

const VideoRow = ({ titolo, posizione, referenti, mail, telefono, cellulare }) => {
    return (
        <div>
            <div className="grid md:grid-cols-5 md:grid-rows-1 gap-4 text-white md:m-8 m-5">
                <div className="md:col-span-3 md:col-start-3 md:row-start-1">
                    <video controls={false} loop={true} preload="auto" muted autoPlay={true} playsInline={true} className="block w-full w-[2000px] h-[1125]">
                        <source src={"https://www.spaziodifferente.com/media/videos/video1-compressed.mp4"} type={`video/mp4`} />
                        Il tuo browser non supporta il tag video.
                    </video>
                </div>
                <div className="md:col-span-2 p-3">
                    <h1 className="text-4xl font-cabin mb-2">{titolo}</h1>
                    <address className="not-italic  mb-4">
                        {posizione.map((linea, index) => (
                            <React.Fragment key={index}>
                                {linea}<br/>
                            </React.Fragment>
                        ))}
                    </address>
                    {referenti.map((referente, index) => (
                        <div className="mb-4" key={index}>
                            <span className="font-cabin ">{referente.split(",")[0]}</span><br/>
                            <span>{referente.split(",")[1]}</span>
                        </div>
                    ))}
                    <div>
                        <span className="font-cabin underline">Contattaci!</span><br/>
                        <a href={`mailto:${mail}`} className="text-blue-600 hover:text-blue-700">{mail}</a>
                        <br></br>
                        <a className="text-white">Cellulare: {cellulare}</a>
                        <br></br>
                        <a className="text-white">Telefono: {telefono}</a>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default VideoRow;