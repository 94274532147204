import React, { useEffect, useState } from "react";
import logo from '../img/logoAnimazioneInit.svg';

const LogoAnimation = ({ bounce, setBounce }) => {
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        const halfTimer = setTimeout(() => {
            setIsDark(true);
        }, 500);

        const fullTimer = setTimeout(() => {
            setBounce(false);
            setIsDark(false);
        }, 1000);

        return () => {
            clearTimeout(halfTimer);
            clearTimeout(fullTimer);
        };
    }, [setBounce]);

    return (
        <div className={`flex justify-center items-center h-screen transition-colors duration-500 ${isDark ? 'bg-black' : 'bg-white'}`}>
            <img src={logo} alt="Logo" className={`${isDark ? 'filter invert' : ''} animate-bounce animate-infinite animate-ease-out w-40 h-40 mb-0`} />
        </div>
    );
};

export default LogoAnimation;
