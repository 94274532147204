import React, {useEffect, useState} from 'react';
import Navbar from "../Componenti/NavBar";
import Intro from "../Componenti/Intro";
import FinalLogo from "../Componenti/FinalLogo";
import VideoRow from "../Componenti/VideoRow";
import NavBarPhones from "../Componenti/NavBarPhones";
import "../fade.css"
import LogoAnimation from "../Componenti/LogoAnimations";
import Footer from "../Componenti/Footer";

function Contatti({ isMenuOpen, setIsMenuOpen, resetMenuMobile }) {
    const datiRows = [
        {
            titolo: "Vicini di Casa - Tecnocasa",
            posizione: ["","Piazza dei Martiri 2," , "40016 San Giorgio di Piano (BO) ", "Emilia-Romagna, Italy"],
            referenti: ["Responsabile, Nicola Bonfiglioli"],
            mail: "bohba@tecnocasa.it",
            cellulare:"338 7114199",
            telefono:"051 6630832"
        },

    ];

    const [bounce, setBounce] = useState(true);

    useEffect(() => {
        resetMenuMobile();
    }, []);

    return (
        <>

            <div className={`${bounce ? "visible" : "hidden"} `}>
                <LogoAnimation bounce={bounce} setBounce={setBounce} ></LogoAnimation>
            </div>

            <div className={`${bounce ? "hidden" : "visible"} ${bounce ? "" : "animate-fade animate-ease-linear"} `}>
                <div className={`${isMenuOpen ? "block" : "hidden"}`}>
                    <NavBarPhones setIsMenuOpen={setIsMenuOpen} />
                </div>
                <div className={`${isMenuOpen ? "hidden" : "block"} main bg-black animate-fade animate-ease-linear`}>

                    <div>
                        <Navbar isMainPage={false} setIsMenuOpen={setIsMenuOpen} isError={false} ></Navbar>
                        <hr className="my-3 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-300 to-transparent opacity-100" />
                    </div>

                    { !bounce ? (
                        <Intro />
                    ) :  null }

                    {datiRows.map((dati, index) => (
                        <React.Fragment key={index}>
                            <hr className="my-0 h-px opacity-80 mx-3" />
                            <VideoRow referenti={dati.referenti} posizione={dati.posizione} mail={dati.mail} titolo={dati.titolo} telefono={dati.telefono} cellulare={dati.cellulare} />
                        </React.Fragment>
                    ))}
                    <hr className="my-0 h-px opacity-80 mx-3" />
                    <FinalLogo />
                    <div>
                        <Footer/>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Contatti;
