import React from "react";
import logo from '../img/logospazioFooter.svg';

const FinalLogo = () => {
    return (
        <div className="flex justify-center items-center md:bg-white bg-black text-white py-4 px-4 w-full ">
            <img src={logo} alt="Logo" className="md:block hidden" />
        </div>
    );
};

export default FinalLogo;
