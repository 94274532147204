import React, {useEffect} from 'react';
import logo from '../img/logoAnimazioneInit.svg';
import Navbar from "../Componenti/NavBar";
import Footer from "../Componenti/Footer";
import {TypeAnimation} from "react-type-animation";
import NavBarPhones from "../Componenti/NavBarPhones";
import {Link} from "react-router-dom";
import NavBar from "../Componenti/NavBar";

function Page404({isMenuOpen, setIsMenuOpen, resetMenuMobile}) {

    useEffect(() => {
        resetMenuMobile()
    }, [])

    return (
        <>
            <div className="hidden md:flex flex-col h-screen">
                <NavBar/>
                <div className="flex-grow flex items-center justify-center p-4">
                    <div className="grid grid-cols-4 gap-4 w-full">
                        <div className="col-span-2 flex justify-center items-center">
                            <p className="font-lora text-9xl">
                                <TypeAnimation
                                    sequence={[
                                        'ERROR 404',
                                        3000,
                                        ''
                                    ]}
                                    speed={5}
                                    repeat={Infinity}
                                    cursor={true}
                                />
                            </p>
                        </div>
                        <div className="col-span-2 flex justify-center items-center">
                            <Link to="/">
                                <img src={logo} alt="Logo" className="w-72 h-72 animate-bounce"/>
                            </Link>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>


            <div className="flex flex-col justify-between min-h-screen md:hidden animate-fade animate-ease-linear">
                <div>
                    <div className={`${isMenuOpen ? "block" : "hidden"} `}>
                        <NavBarPhones setIsMenuOpen={setIsMenuOpen}></NavBarPhones>
                    </div>
                    <div className={`${isMenuOpen ? "hidden" : "block"} animate-fade animate-ease-linear`}>

                        <div>
                            <Navbar isMainPage={false} setIsMenuOpen={setIsMenuOpen} isError={true}></Navbar>
                            <hr className="my-3 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-black to-transparent opacity-100" />
                        </div>

                        <div className="relative flex justify-center items-start">
                            <div className="flex flex-col justify-start items-center space-y-24 pt-10">
                                <p className="font-lora text-2xl">
                                    <TypeAnimation
                                        sequence={[
                                            'ERROR 404',
                                            3000,
                                            ''
                                        ]}
                                        speed={5}
                                        repeat={Infinity}
                                        cursor={true}
                                    />
                                </p>
                                <Link to={"/"}>
                                    <img src={logo} alt="Logo" className="w-40 h-40 animate-bounce"/>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer></Footer>
            </div>


        </>
    );
}

export default Page404;
