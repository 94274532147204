import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Storia from './Pages/Storia';
import Gallery from "./Pages/Gallery"
import Contatti from "./Pages/Contatti"
import Servizi from "./Pages/Servizi"
import Page404 from "./Pages/Page404"

import ScrollToTop from "./Componenti/scrollToTop";
import {Toaster} from "sonner";


function App() {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const resetMenu = () => {
        setIsMenuOpen(false)
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    const checkScreenWidth = () => {
        if (window.innerWidth <= 991) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        checkScreenWidth();
        document.querySelector("body").classList.add("index3");
        window.addEventListener('resize', checkScreenWidth);
        return () => {
            window.removeEventListener('resize', checkScreenWidth);
        };
    }, []);

    return (
        <>
            <Router>
                <Toaster position="top-center" expand={false} richColors visibleToasts={1} theme={"light"}/>
                <ScrollToTop />

                <Routes>
                    <Route exact path="/" element={<HomePage isMenuOpen={isMenuOpen} setIsMenuOpen={toggleMenu} resetMenuMobile={resetMenu} />}  />
                    <Route path="/contatti" element={<Contatti isMenuOpen={isMenuOpen} setIsMenuOpen={toggleMenu} resetMenuMobile={resetMenu} />} />
                    <Route path="/galleria" element={<Gallery isMenuOpen={isMenuOpen} setIsMenuOpen={toggleMenu} resetMenuMobile={resetMenu} />} />
                    <Route path="/servizi" element={<Servizi isMenuOpen={isMenuOpen} setIsMenuOpen={toggleMenu} resetMenuMobile={resetMenu} isMobile={isMobile} />} />
                    <Route path="/storia" element={<Storia isMenuOpen={isMenuOpen} setIsMenuOpen={toggleMenu} resetMenuMobile={resetMenu}  />} />
                    <Route path="*" element={<Page404 isMenuOpen={isMenuOpen} setIsMenuOpen={toggleMenu} resetMenuMobile={resetMenu} />} />
                </Routes>

            </Router>

        </>
    );
}
export default App;
