import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import freccia from '../img/icons/arrow.svg'

function NavBarPhones({ setIsMenuOpen }) {

    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);


    const renderLink = (path, text) => {
        const isActive = path === activeLink;
        return (
            <Link to={path} className={`block font-lora text-black text-5xl mb-3 flex items-center `}>
                {text}
                {isActive && <img src={freccia} alt="Active Link Arrow" className="ml-2 h-6" />}
            </Link>
        );
    };


    return (
        <div className="flex flex-col bg-white p-4 animate-fade animate-once animate-duration-[1500ms]">
            <div className="flex justify-between items-center">
                <Link to="/">
                    <h1 className="text-2xl font-lora underline" >SPAZIO Differente</h1>
                </Link>
                <button onClick={setIsMenuOpen} className="font-cabin ">Close ✕</button>
            </div>

            <div className="animate-fade animate-once animate-duration-[4000ms] mt-10">
                {renderLink("/storia", "Storia")}
                {renderLink("/servizi", "Servizi")}
                {renderLink("/galleria", "Gallery")}
                {renderLink("/contatti", "Contatti")}
            </div>
        </div>
    );
}

export default NavBarPhones;
