import React from 'react'
import HomeRows from './HomeRows'
import ServiceRows from './ServiceRows'
//
const Info = ({ dati, flag }) => {
    const RowComponent = flag === 'Homepage' ? HomeRows : ServiceRows;

    return (
        <div>
            {dati.map((item, index) => (
                <React.Fragment key={index}>
                    <RowComponent titolo={item.titolo} testo={item.testo} contatti={item.contatti} link={item.link} icona={item.icona} />
                    {index < dati.length - 1 && <hr className="my-0 h-px opacity-80 bg-gray-700 mb-10" />}
                </React.Fragment>
            ))}
        </div>
    );
};

export default Info;
