import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const markerIcon = new L.Icon({
    iconUrl: require('../img/icons/pin.png'),
    iconSize: [45, 45],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const Gmaps = ({ isInit }) => {
    const celesteCesariCoords = [44.645247, 11.384604];
    const isMobile = window.innerWidth <= 480;

    return (
        <div style={{ height: '500px', width: '100%' }}>
            {!isInit ? (
                <MapContainer
                    center={celesteCesariCoords}
                    zoom={20}
                    scrollWheelZoom={false}
                    dragging={!isMobile}
                    tap={!isMobile}
                    style={{ height: '100%', width: '100%' }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={celesteCesariCoords} icon={markerIcon}>
                        <Popup>
                            Via A. Stanghellini, San Giorgio di Piano.
                        </Popup>
                    </Marker>
                </MapContainer>
            ) : null}
        </div>
    );
};

export default Gmaps;
